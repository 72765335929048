
import { Component, Mixins, Prop,} from "vue-property-decorator";
import ScoutingReportDetailProvider, { SaveReportFn } from "@/components/hoc/ScoutingReportDetailProvider.vue";
import { formatDate, formatDatePretty } from "@/helpers/date";
import { MyCoachMixin, VuetifyMixin, BAIconsMixin } from "@/mixins";
import ScoreSelector from '@/components/forms/ScoreSelector.vue';
import RadarChartJS from "@/components/charts/RadarChartJS.vue";
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import { title } from '../pipes';
import { CoachScoutingSummary } from '@/../types/constants/web_client_user.routes';
import ScoutingReportSpiderChart from "@/components/scoutingReport/ScoutingReportSpiderChart.vue";
import ScoutingReportMatrix from "@/components/scoutingReport/ScoutingReportMatrix.vue";
import ScoutingReportMatrixV2 from "@/components/scoutingReport/ScoutingReportMatrixV2.vue";
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { Route } from 'vue-router';
import { notificationStore } from '@/store';
import ScoutingReportNotesBtn from "@/components/scoutingReport/ScoutingReportNotesBtn.vue";
import { ScoutingReportEvaluationMatrix } from '@best-athletes/ba-types';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import html2pdf from 'html2pdf.js'
import 'jspdf-autotable';

@Component({
	components: {
		ScoutingReportDetailProvider,
		ScoreSelector,
		RadarChartJS,
		ScoutingReportMatrix,
		ScoutingReportMatrixV2,
		ScoutingReportSpiderChart,
		ScoutingReportNotesBtn,
	},
	
})

export default class ViewScoutingReport extends Mixins(MyCoachMixin, VuetifyMixin, ScoutingReportScoreSelectorMixin, BAIconsMixin ){
	pdfContent: null;
	previewDialog: boolean = false;
	previewURL: string | null = null;
	formatDate = formatDate;
	formatDatePretty = formatDatePretty;
	title = title;
	@Prop({ default: null, type: String }) reportId: string | null;
	@Prop({ default: null, type: String }) athleteId: string | null;
	submitDialogVisible: boolean = false;
	loading: boolean = false;
	email: string = "";
	errorMessage: string = "";
	get CoachName(): string{
		return `${this.MyCoachProfile.firstName} ${this.MyCoachProfile.lastName}`
	}
	get EvaluationCols(): {left: Record<string, any>, right: Record<string, any>}{
		return {
			left: {
				cols:"12", md:"3", lg:"4",
			},
			right: {
				cols:"12", md:"9", lg:"8",
			},
		}
	}


	async initialSubmit(validate: () => Promise<boolean>): Promise<void> {
		const isValid = await validate();
		if (!isValid) {
			this.errorMessage = 'Please ensure all required fields are provided.';
			this.$vuetify.goTo(0);
		}
		else {
			this.submitDialogVisible = true;
		}
	}

	cancelSubmit(): void{
		this.submitDialogVisible = false;
		this.email = "";
	}
	
	goToScoutingSummary(): void{
		this.$router.push({
			name: CoachScoutingSummary,
			params:{
				...this.$route.params,
			}
		});
	}
	
	async sendEmail(saveReport: SaveReportFn, report: ScoutingReportModel, email: string, validate: () => Promise<boolean>): Promise<void>{
		const isValid = await validate();
		if (isValid){
			this.loading = true;
			let newReport = report;
			newReport.contactInfo.email = email;
			try{
				await saveReport(newReport, true);
				this.goToScoutingSummary();
			}catch(e){
				console.error("Failed to save scouting report", e);
				notificationStore.pushLocalNotificationMessage("Failed to save scouting report. Please try again later");
			}finally{
				this.loading = false;
			}
		}
	}
	goToScoutingReports(): void{
		this.$router.push({ name: CoachScoutingSummary });
	}

	viewAthleteRoute(athleteId: string): Partial<Route>{
		return {
			name: "CoachViewAthlete",
			params:{
				athleteId
			}
		}
	}

	loadingPDF: boolean = false;
	set LoadingPDF(newLoading: boolean) {
		if (newLoading === false)
			setTimeout(() => this.loadingPDF = false, 1000);
		else
			this.loadingPDF = true;
	}
	get LoadingPDF(): boolean {
		return this.loadingPDF;
	}
	generatePDF() {
		this.LoadingPDF = true;
		const styles = `
			.pdf-content {
			width: 2048px;
			height: auto;
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			}
		`;
		const elements = document.getElementsByClassName('pdf-container');
		const element = elements[0] as HTMLElement;
		html2canvas(element).then((canvas) => {
			const imgData = canvas.toDataURL();
			const imgExtension = imgData.substring(11, imgData.indexOf(';'));
			const imageType = imgExtension === 'jpeg' ? 'jpg' : imgExtension;
			const pdf = new jsPDF();
			const imgProps = pdf.getImageProperties(imgData);
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
			let currentPage = 0;
			const pageHeight = pdf.internal.pageSize.getHeight();
			while (currentPage < pdfHeight) {
			let newHeight = currentPage + pageHeight <= pdfHeight ? pageHeight : pdfHeight - currentPage;
			pdf.addPage();
			pdf.addImage(imgData, imageType, 0, -currentPage, pdfWidth, imgProps.height / imgProps.width * pdfWidth, undefined, 'FAST');
			currentPage += newHeight;
			}
			pdf.deletePage(1);
			const pdfData = pdf.output('blob');
			const pdfUrl = URL.createObjectURL(pdfData);
			const previewWindow = window.open('', '_blank');
			const styleElement = previewWindow.document.createElement('style');
			styleElement.innerHTML = styles;
			previewWindow.document.head.appendChild(styleElement);
			previewWindow.document.write(`<div class="pdf-content"><iframe src="${pdfUrl}" style="width: 100%; height: 100%;"></iframe></div>`);
		});
		this.LoadingPDF = false;
	}
  }

